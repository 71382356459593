<template>
    <div>

        <div class="container">
            <div class="row">
                <div class="col-md-12">
              

                    <div class="card">
                        <div class="card-body table-responsive">

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Departments</th>
                                        <th>Department Head</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Accounting</td>
                                        <td>Mr. Sani Kadiru</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                </div>
            </div>
        </div>



    </div>
</template>

<script>


export default {
    components: {

    },
}
</script>

<style></style>
